#root {
    margin: 0;
    overflow-x: hidden !important;
    font-size: .845rem;
    background-color: #edf0f5;
    color: #245afc;
    min-height: 100vh;
    letter-spacing: 0.1px;
    line-height: 1.5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    width: 100%;
}

.account-body.accountbg>#root {
    background-color: transparent;
}

.module-link {
    text-transform: capitalize;
    background: transparent;
    border: none;
    padding-left: 20px;
    color: #303e67;
    transition: all .3s;
}

.module-link:hover {
    color: #1761fd;
}



.topbar .topbar-left .logo .logo-lg {
    height: 40px;
}

.account-body .account-social button {
    padding: 0;
    border-radius: 50%;
    border: none;
    cursor: pointer;
}

.account-body .account-social button i.google {
    background-color: #fd3c97;
    color: #fff;
}

.account-body .account-social button i {
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    font-size: 14px;
    z-index: 5;
}

.account-body .account-social button:hover .google {
    color: #fff;
    background-color: #fd55a4;
}

.card.loading>.card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
}

.cursor-pointer {
    cursor: pointer;
}

.truncate {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 5px;

}

.custom-scrollbar-horizontal::-webkit-scrollbar {
    height: 5px;
}

.scrollbar-light::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, .3);
    border-radius: 10px;
}

.scrollbar-dark::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .3);
    border-radius: 10px;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
    background: #034bd9;
    border-radius: 10px;
}

.overlay-menu-mobile {
    display: none;
}


@media (max-width: 1025px) {
    .overlay-menu-mobile {
        position: absolute;
        background: rgba(0, 0, 0, .3);
        width: 100vw;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 999;
        display: block !important;
    }

    .custom-menu {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9999;
        display: block !important;

    }
}

.gradient-1 {
    background: #f0a907;
    background: -moz-linear-gradient(top, #f0a907 0%, #f53c79 100%);
    background: -webkit-linear-gradient(top, #f0a907 0%, #f53c79 100%);
    background: linear-gradient(to bottom, #f0a907 0%, #f53c79 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0a907', endColorstr='#f53c79', GradientType=0);
}

.gradient-2 {
    background: #4dedf5;
    background: -moz-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
    background: -webkit-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
    background: linear-gradient(to bottom, #4dedf5 0%, #480ceb 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4dedf5', endColorstr='#480ceb', GradientType=0);
}

.gradient-3 {
    background: #51f5ae;
    background: -moz-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
    background: -webkit-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
    background: linear-gradient(to bottom, #51f5ae 0%, #3fbcda 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#51f5ae', endColorstr='#3fbcda', GradientType=0);
}

.gradient-4 {
    background: #f25521;
    background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
    background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
    background: linear-gradient(to right, #f25521 0%, #f9c70a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f25521', endColorstr='#f9c70a', GradientType=1);
}

.gradient-5 {
    background: #f53c79;
    background: -moz-linear-gradient(left, #f53c79 0%, #f0a907 100%);
    background: -webkit-linear-gradient(left, #f53c79 0%, #f0a907 100%);
    background: linear-gradient(to right, #f53c79 0%, #f0a907 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f53c79', endColorstr='#f0a907', GradientType=1);
}

.gradient-6 {
    background: #36b9d8;
    background: -moz-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
    background: -webkit-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
    background: linear-gradient(to right, #36b9d8 0%, #4bffa2 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#36b9d8', endColorstr='#4bffa2', GradientType=1);
}

.gradient-7 {
    background: #4400eb;
    background: -moz-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
    background: -webkit-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
    background: linear-gradient(to right, #4400eb 0%, #44e7f5 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4400eb', endColorstr='#44e7f5', GradientType=1);
}

.gradient-8 {
    background: #F7B00F;
    background: -moz-linear-gradient(top, #F7B00F 0%, #F25521 100%);
    background: -webkit-linear-gradient(top, #F7B00F 0%, #F25521 100%);
    background: linear-gradient(to bottom, #F7B00F 0%, #F25521 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F7B00F', endColorstr='#F25521', GradientType=1);
}

.gradient-9 {
    background: #f31e7a !important;
    background: -moz-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
    background: -webkit-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
    background: linear-gradient(to right, #f31e7a 0%, #fd712c 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f31e7a', endColorstr='#fd712c', GradientType=1);
}

.gradient-10 {
    background: #f25521 !important;
    background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
    background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
    background: linear-gradient(to top, #f25521 0%, #f9c70a 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f25521', endColorstr='#f9c70a', GradientType=1);
}

.gradient-11 {
    background: #3398fb;
    background: -moz-linear-gradient(left, #3398fb 0%, #8553ee 100%);
    background: -webkit-linear-gradient(left, #3398fb 0%, #8553ee 100%);
    background: linear-gradient(to right, #3398fb 0%, #8553ee 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3398fb', endColorstr='#8553ee', GradientType=1);
}

.gradient-12 {
    background: #36e1b4;
    background: -moz-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
    background: -webkit-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
    background: linear-gradient(to right, #36e1b4 0%, #11cae7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#36e1b4', endColorstr='#11cae7', GradientType=1);
}

.gradient-13 {
    background: #ffbf31;
    background: -moz-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
    background: -webkit-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
    background: linear-gradient(to right, #ffbf31 0%, #ff890e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbf31', endColorstr='#ff890e', GradientType=1);
}

.gradient-14 {
    background: #23bdb8;
    background: -moz-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
    background: -webkit-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#23bdb8', endColorstr='#43e794', GradientType=1);
}

.gradient-15 {
    background: #9a56ff;
    background: -moz-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
    background: -webkit-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
    background: linear-gradient(135deg, #9a56ff 0%, #e36cd9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9a56ff', endColorstr='#e36cd9', GradientType=1);
}

.gradient-16 {
    background: #f48665;
    background: -moz-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
    background: -webkit-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
    background: linear-gradient(135deg, #f48665 0%, #fda23f 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f48665', endColorstr='#fda23f', GradientType=1);
}

.gradient-17 {
    background: #e36cd9;
    background: -moz-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
    background: -webkit-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
    background: linear-gradient(135deg, #e36cd9 0%, #fe60ae 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e36cd9', endColorstr='#fe60ae', GradientType=1);
}

.gradient-18 {
    background: #a15cff;
    background: -moz-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
    background: -webkit-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
    background: linear-gradient(to right, #a15cff 0%, #ce82fd 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a15cff', endColorstr='#ce82fd', GradientType=1);
}